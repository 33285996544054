import { Admin, Resource, Layout, Menu, CustomRoutes } from "react-admin";
import { BrowserRouter, Route } from 'react-router-dom';

import graphqlDataProvider from "./api/graphqlDataProvider";
import authProvider from "./api/authProvider";
import eventComponents from "./resources/event";
import providerComponents from "./resources/provider";
import openSearchComponents from "./resources/open-search";

import { ApiariSideMenu } from './components';
import { DashboardPage , MatchingInsightsPage} from './pages';


import {
  People as PeopleIcon,
  BabyChangingStation as BabyChangingStationIcon,
  CalendarMonth as CalendarMonthIcon,
  RequestQuote as RequestQuoteIcon,
  FolderShared as FolderSharedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

import customerComponents from "./resources/customer";
import payrollComponents from "./resources/payroll";
import statsComponents from "./resources/stats";

const MyLayout = props => <Layout  {...props} menu={ApiariSideMenu} />

function App() {
  if (!graphqlDataProvider) return null;

  return (
    <BrowserRouter>
    <Admin layout={MyLayout} dashboard={DashboardPage} dataProvider={graphqlDataProvider} authProvider={authProvider}>
      <Resource
        name="EventInstance"
        options={{ label: "Sessions" }}
        icon={CalendarMonthIcon}
        {...eventComponents}
      />
      <Resource
        name="Provider"
        options={{ label: "Providers" }}
        icon={BabyChangingStationIcon}
        {...providerComponents}
      />
      <Resource
        name="Customer"
        options={{ label: "Customers" }}
        icon={PeopleIcon}
        {...customerComponents}
      />
      <Resource
        name="Payroll"
        options={{ label: "Payroll" }}
        icon={RequestQuoteIcon}
        {...payrollComponents}
      />
      <Resource
        name="Stats"
        options={{ label: "Provider Dashboard" }}
        icon={FolderSharedIcon}
        {...statsComponents}
      />
      <Resource
        name="OpenSearch"
        options={{ label: "Open Searches" }}
        icon={SearchIcon}
        {...openSearchComponents}
      />
      <Resource name="Location" options={{ hide: true }} /> 
      <CustomRoutes>
        <Route path="/matching-insights" element={<MatchingInsightsPage />} />
      </CustomRoutes>
    </Admin>
    </BrowserRouter>
  );
}

export default App;
