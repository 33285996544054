import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Grid, Card, CardContent } from "@mui/material";
import {
  useNotify,
  Title,
  Datagrid,
  ListContextProvider,
  useList,
  ReferenceField,
  TextField,
} from "react-admin";

import HttpProvider from '../../api/httpProvider'
import { ImportCriteriaPanel } from './ui/import-criteria-panel';
import { HardCriteriaPanel } from './ui/hard-criteria-panel';
import {
  SimpleTextField,
  UserNameField,
  GeolocationField,
  TruthyField,
  OperationRadiusField,
  ChipArrayField,
  ServiceNameArrayField,
} from "../../components";

export const MatchingInsightsPage = () => {
  const notify = useNotify();
  const [defaultCriteria, setCriteria] = useState({
    serviceName: "",
    numDogs: 0,
    numCats: 0,
    enrichmentActivities: [],
    specialNeeds: false,
    children: [{}],
    geoLocation: {
      lat: 0,
      lng: 0,
    },
    excludeProviders: [],
    restrictToProviders: []
  });


  const { data, mutate } = useMutation({
    mutationFn: (matchingCriteria) => {
      return HttpProvider.matchByHardCriteria(matchingCriteria);
    },
    onError: (error) => notify(error.message, { type: "error" }),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const providersListContext = useList({ data: data ?? [], resource: "Provider" });

  return (
    <Grid
      sx={{
        paddingTop: "32px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",

        gap: "32px",
      }}
    >
      <Title title="Matching Insights" />
      <Card>
        <CardContent>
          <HardCriteriaPanel
            defaultCriteria={defaultCriteria}
            matchByHardCirteria={mutate}
          />
        </CardContent>
      </Card>

      <Card sx={{ position: "relative" }}>
        <CardContent>
          <ImportCriteriaPanel setCriteria={setCriteria} />
        </CardContent>
      </Card>

      {data && (
        <Card sx={{ gridColumn: "1 / -1" }}>
          <CardContent>
            <ListContextProvider value={providersListContext}>
              <Datagrid
                empty="No providers matched the criteria"
                resource="Provider"
              >
                <SimpleTextField source="shortUserId" defaultText="0" />

                <ReferenceField
                  source="id"
                  reference="Provider"
                  link="show"
                  label="Full Name"
                >
                  <UserNameField />
                </ReferenceField>

                <TextField source="address.zipcode" label="Zipcode" />
                <GeolocationField label="Geolocation" labeled={false} />
                <TruthyField source="hasSpecialNeedsExperience" />
                <OperationRadiusField label="Operation radius" labeled={false} />
                <ServiceNameArrayField source="services" label="Performs Services" />
                <ChipArrayField source="enrichmentActivities" />
              </Datagrid>
            </ListContextProvider>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};
