import retry from 'async-retry';
import { gql } from '@apollo/client';
import buildGraphqlProvider from 'ra-data-graphql-simple'
import graphQLClient from './graphqlClient'

const pageSize = 1000
const _getListSymbol = Symbol();

const graphqlDataProvider = buildGraphqlProvider({
  client: graphQLClient,
  introspection: {},
});

graphqlDataProvider[_getListSymbol] = graphqlDataProvider.getList;

graphqlDataProvider.promoteProviders = async (ids) => {
  return await graphQLClient.mutate({
    mutation: gql`
      mutation promoteProviders($ids: [Int]) {
        promoteProviders(ids: $ids) {
          debug
          matches {
            matchingCriteria {
              customer {
                email
                shortUserId
                firstName
                lastName
              }
              serviceName
              restrictToProviders
              numDogs
              numCats
              children {
                birthdayTimestamp
              }
              specialNeeds
              geoLocation {
                lng
                lng
              }
              enrichmentActivities
            }
            providers {
              shortUserId
              email
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: { ids },
  });
};

graphqlDataProvider.getList = (resource, params) => {
  if (params?.pagination?.perPage > pageSize) {
    return new Promise(async (resolve, reject) => {
      const bulkResult = [];

      const getPageOfData = async (page) => {
        try {
          const result = await retry(
            async () =>
              graphqlDataProvider[_getListSymbol](resource, {
                ...params,
                pagination: { page, perPage: pageSize },
              }),
            {
              retries: 3,
              onRetry: (err) => {
                console.info(`Retry caused by:`, err);
              },
            }
          );

          bulkResult.push(...result.data);

          if (result.data.length >= pageSize) {
              await getPageOfData(page + 1);
          }
        } catch (err) {
          return reject(err);
        }
      }

      await getPageOfData(1);

      resolve({
        data: bulkResult,
        total: bulkResult.length,
      });
    });
  } else {
    return graphqlDataProvider[_getListSymbol](resource, params);
  }
} 

export default graphqlDataProvider;