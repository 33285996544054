import { useState } from "react";
import { Menu } from "react-admin";
import { Link } from "react-router-dom"
import {
  useTheme,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

import {
  BabyChangingStation as BabyChangingStationIcon,
  ManageAccounts as ManageAccountsIcon,
  Troubleshoot as TroubleshootIcon,
  CreditCard as CreditCardIcon,
  FolderShared as FolderSharedIcon,
  QueryStats as QueryStatsIcon
} from "@mui/icons-material";

const { REACT_APP_WEB_URL: webUrl } = process.env;

const ApiariSideMenu = () => {
  const muiTheme = useTheme()
  const [openProvidersItem, setOpenProvidersItem] = useState(true);


  return (
    <Menu>
      <Menu.DashboardItem />
      <Menu.ResourceItem name="EventInstance" />

      <List
        component="nav"
        disablePadding
        sx={{
          color: muiTheme.palette.text.secondary,
          "& .MuiListItemIcon-root": { minWidth: "40px" },
        }}
      >
        <ListItemButton
          onClick={() => setOpenProvidersItem(!openProvidersItem)}
        >
          <ListItemIcon>
            <BabyChangingStationIcon />
          </ListItemIcon>
          <ListItemText primary="Providers" />
        </ListItemButton>

        <Collapse in={openProvidersItem} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItemButton component={Link} to="/Provider" sx={{ pl: 4 }}>
              <ListItemIcon>
                <FolderSharedIcon />
              </ListItemIcon>
              <ListItemText primary="All providers" />
            </ListItemButton>

            <ListItemButton component={Link} to="/Subsriber" sx={{ pl: 4 }}>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary="Subscribers" />
            </ListItemButton>

            <ListItemButton component={Link} to="/Stats" sx={{ pl: 4 }}>
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="Statistic" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>

      <Menu.ResourceItem name="Customer" />
      <Menu.ResourceItem name="Payroll" />
      <Menu.ResourceItem name="OpenSearch" />
      <Menu.Item
        to="/matching-insights"
        primaryText="Matching Insights"
        leftIcon={<TroubleshootIcon />}
      />
      <Menu.Item
        to={`${webUrl}/chat-bot?admin=1`}
        primaryText="Amy for Admin"
        leftIcon={<ManageAccountsIcon />}
      />
    </Menu>
  );
};

export default ApiariSideMenu;
